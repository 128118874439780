<!-- eslint-disable vue/no-v-html -->
<template>
  <v-alert
    v-show="showAlert"
    text
    prominent
    :type="alert.type"
    :icon="alert.icon"
    :transition="alert.transition"
  >
    <span v-html="alert.message" />
  </v-alert>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState('AlertModule', ['alert']),
    ...mapGetters('AlertModule', ['showAlert']),
  },

  watch: {
    showAlert(newVal) {
      if (newVal && this.alert.timeout) setTimeout(() => this.setAlert(), this.alert.timeout);
    },
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
  },
};
</script>
