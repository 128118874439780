<template>
  <v-app id="inspire">
    <v-main>
      <Alert />

      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Alert from '../components/Alert';

export default {
  components: {
    Alert,
  },

  props: {
    source: {
      type: String,
      default: '',
    },
  },
};
</script>
