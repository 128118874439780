<template>
  <component :is="$route.meta.layout">
    <v-card class="elevation-12">
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <v-toolbar-title>Login form</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            id="username"
            v-model="formData.username"
            data-cy="username"
            label="Username"
            name="username"
            prepend-icon="mdi-account"
            type="text"
            autocomplete="username"
            autofocus
            :rules="formValidation.username"
            @keyup.enter="login"
          />

          <v-text-field
            id="password"
            v-model="formData.password"
            data-cy="password"
            label="Password"
            name="password"
            prepend-icon="mdi-lock"
            type="password"
            autocomplete="current-password"
            :rules="formValidation.password"
            @keyup.enter="login"
          />

          <div
            v-show="captchaKey"
            ref="widget"
            class="frc-captcha"
            data-lang="en"
            :data-sitekey="captchaKey"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="cy-submit"
          color="primary"
          @click="login"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
  </component>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { WidgetInstance } from 'friendly-challenge';
import { redirectTo, setSession } from '../../helpers/auth';

export default {
  data: () => ({
    valid: true,
    captchaKey: process.env.VUE_APP_FC_APIKEY || false,
    widget: false,
    formData: {
      username: '',
      password: '',
      challenge: undefined,
    },
    formValidation: {
      email: [
        (v) => !!v || 'Please type your username.',
        (v) => v.length <= 3 || 'Please type a valid username.',
      ],
      password: [(v) => !!v || 'Please type your password.'],
    },
  }),

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (!this.widget) {
          this.widget = new WidgetInstance(this.$refs.widget, {
            startMode: 'auto',
            sitekey: this.captchaKey,
            doneCallback: this.captchaDoneCallback,
          });
        } else {
          this.widget.reset();
        }
      }, 0);
    });
  },

  methods: {
    ...mapMutations('AlertModule', ['setAlert']),
    ...mapActions('auth', { asyncLogin: 'login' }),
    ...mapActions('ScopesModule', ['listScopes']),

    async login() {
      if (this.$refs.form.validate()) {
        try {
          const res = await this.asyncLogin(this.formData);
          await this.listScopes();
          setSession(res.data.accessToken, res.data.accessTokenExpiry);

          this.$router.push({ name: redirectTo() });
        } catch (error) {
          let alertMessage = 'The entered information could not be used to log you in. Please try again.';
          if (error.response.status === 400) alertMessage = 'Our Captcha identified you as a Bot. If this is not correct please refresh your browser and attempt to login again.';
          if (error.response.status === 403) alertMessage = 'Your account is inactive and therefore you cannot proceed to login. In case you think this is a mistake, please contact your Administrator.';
          if (error.response.status === 429) alertMessage = 'Too many failed login attempts, please wait two minutes before next attempt.';
          this.setAlert({
            message: alertMessage,
          });
        }
      }
    },

    captchaDoneCallback(response) {
      this.formData.challenge = response;
    },
  },
};
</script>
